<template>
  <div style="margin: 20px">
    <v-table :fetch="fetchData" name="periods" :columns="columns"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getFactPeriodsList } from '@/api/api'
import { getFormatDate } from '@/utils'
export default {
  components: {
    VTable
  },
  data () {
    return {

    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'date_start',
          filter: getFormatDate
        },
        {
          field: 'date_stop',
          filter: getFormatDate
        },
        {
          field: 'period_separation',
          filter: getFormatDate
        },
        {
          field: 'is_monthly',
          filter (value) {
            return value ? 'Да' : 'Нет'
          },
          items: [
            {
              id: 0,
              title: 'Нет'
            },
            {
              id: 1,
              title: 'Да'
            }
          ]
        }
      ]
    }
  },
  methods: {
    fetchData: (...args) => getFactPeriodsList(...args),
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'create') })
    }
  }
}
</script>

<style scoped>

</style>
